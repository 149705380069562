import {
  call,
  put,
  select,
  takeEvery,
  all,
  takeLatest,
  fork,
} from 'redux-saga/effects';
import api from '../api';
import { DrActions } from '../actions';
import * as DrSelectors from '../reducers/dr';
import { getTokens } from '../reducers/oauth';
import { toError } from '../../utils';

const { actionTypes } = DrActions;

function* fetchGroupsUsers(groupId) {
  try {
    yield put(DrActions.fetchGroupsEventsRequest());

    const { accessToken } = yield select(getTokens);

    const response = yield call(api.olivineDr.getGroupsDrEvents, groupId, accessToken);

    yield put(DrActions.fetchGroupsEventsSuccess(groupId, response));

    for (let i = 0; i < response.events.length; i += 1) {
      yield put(DrActions.fetchEventSuccess(response.events[i]));
    }
  } catch (err) {
    yield put(DrActions.fetchGroupsEventsFailure(toError(err)));
  }
}

function* postNewEvent(groups, startTime, endTime) {
  try {
    yield put(DrActions.postNewEventRequest());

    const { accessToken } = yield select(getTokens);

    const response = yield call(api.olivineDr.postNewEvent, groups, startTime, endTime, accessToken);

    yield put(DrActions.postNewEventSuccess(response));

    yield put(DrActions.fetchEventSuccess(response));
  } catch (err) {
    yield put(DrActions.postNewEventFailure(toError(err)));
  }
}

function* deleteEvent(eventId) {
  try {
    yield put(DrActions.deleteEventRequest());

    const { accessToken } = yield select(getTokens);

    const response = yield call(api.olivineDr.deleteEvent, eventId, accessToken);

    yield put(DrActions.deleteEventSuccess(eventId, response));
  } catch (err) {
    yield put(DrActions.deleteEventFailure(toError(err)));
  }
}

function* fetchEvent(eventId) {
  try {
    yield put(DrActions.fetchEventRequest());

    const { accessToken } = yield select(getTokens);

    const response = yield call(api.olivineDr.getEvent, eventId, accessToken);

    yield put(DrActions.fetchEventSuccess(response));
  } catch (err) {
    yield put(DrActions.fetchEventFailure(toError(err)));
  }
}

function* fetchEventUsers(eventId) {
  try {
    yield put(DrActions.fetchEventUsersRequest());

    const { accessToken } = yield select(getTokens);

    const response = yield call(api.olivineDr.getEventUsers, eventId, accessToken);

    yield put(DrActions.fetchEventUsersSuccess(response, eventId));
  } catch (err) {
    yield put(DrActions.fetchEventFailure(toError(err)));
  }
}

function* fetchUserEventPerformance(userId, eventId) {
  try {
    yield put(DrActions.fetchUserEventPerformanceRequest());

    const { accessToken } = yield select(getTokens);

    const response = yield call(api.olivineDr.getUserEventPerformance, userId, eventId, accessToken);

    yield put(DrActions.fetchUserEventPerformanceSuccess(response.performance, userId, eventId));
  } catch (err) {
    yield put(DrActions.fetchUserEventPerformanceFailure(toError(err)));
  }
}

function* fetchAllEventPerformance(eventId) {
  let allUsers = yield select(DrSelectors.getUsers);

  if (!allUsers[eventId]) {
    yield call(fetchEventUsers, eventId);
    allUsers = yield select(DrSelectors.getUsers);
  }

  const { users } = allUsers[eventId];

  for (let i = 0; i < users.length; i += 1) {
    yield fork(fetchUserEventPerformance, users[i].id, eventId);
  }
}

function* performByAction(action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_GROUPS_EVENTS:
      return yield call(fetchGroupsUsers, payload.groupId);
    case actionTypes.POST_NEW_EVENT:
      return yield call(postNewEvent, payload.groups, payload.startTime, payload.endTime);
    case actionTypes.DELETE_EVENT:
      return yield call(deleteEvent, payload.eventId);
    case actionTypes.FETCH_EVENT:
      return yield call(fetchEvent, payload.eventId);
    case actionTypes.FETCH_EVENT_USERS:
      return yield call(fetchEventUsers, payload.eventId);
    case actionTypes.FETCH_ALL_EVENT_PERFORMANCE:
      return yield call(fetchAllEventPerformance, payload.eventId);
    case actionTypes.FETCH_USER_EVENT_PERFORMANCE:
      return yield call(fetchUserEventPerformance, payload.userId, payload.eventId);
    default:
      return null;
  }
}

function* drSaga() {
  yield all([
    takeEvery([
      actionTypes.FETCH_GROUPS_EVENTS,
      actionTypes.POST_NEW_EVENT,
      actionTypes.DELETE_EVENT,
      actionTypes.FETCH_EVENT,
      actionTypes.FETCH_EVENT_USERS,
      actionTypes.FETCH_USER_EVENT_PERFORMANCE,
    ], performByAction),
    takeLatest([
      actionTypes.FETCH_ALL_EVENT_PERFORMANCE,
    ], performByAction),
  ]);
}

export default drSaga;
